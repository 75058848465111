.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.img {
  position: relative;

  img {
    @include object-fit(cover);
  }
}

.drop-shadow {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
}

.light-text {
  color: color(light-green);
}

.wave-bg {
  position: relative;
  background: linear-gradient(302.38deg, #003945 21.36%, #004F5F 49.59%);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: repeating-linear-gradient(
      to right,
      transparent,
      transparent 200px,
      #014554 200px,
      #014554 201px
    );
  }

  @include media($md) {
    z-index: 1;

    &:after {
      content: '';
      display: block;
      background: url('../grafix/wave.svg') no-repeat bottom right;
      background-size: 100% auto;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: .3;
    }

    &.page-header--small:after {
      // background-position: top right;
      background-size: 80% auto;
    }
  }
}

.featherlight-next span,
.featherlight-previous span {
  text-shadow: none;
  opacity: 0.3;
  font-size: pxtoem(60);
}
