//************************************************************************************
// BASE
//************************************************************************************


html {
  font-family: $default-font;
  font-size: 1.2em;
  color: $text-color;
  box-sizing: border-box;

  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);

  @include media($md) {
    font-size: 1em;
  }
}

body {
  // @include font-size($font-size + 2);
  @include font-size($font-size);
  font-weight: 200;
  line-height: 1.4;
  position: relative;
  background-color: #FFF;

  // @include media($md) {
    // @include font-size($font-size);
  // }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::selection {
  background: rgba(color(brand-tertiary), .7);
  text-shadow: none;
}

p,
dl,
ul,
ol {
  margin-top: 0;
  margin-bottom: $paragraph-spacing;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
  color: inherit;
  transition: color $transition;
}

img {
  display: block;
  max-width: 100%;
}

button {
  border: none;
  background: transparent;
}

address {
  font-style: normal;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";

  &:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  & p {
    display: inline;
  }
}
