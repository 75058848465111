.realisatie-spotlight {
  background-color: color(brand-tertiary);
  z-index: 2;

  .text {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .image {
    @include break-out();
    display: block;
    height: 400px;
  }

  @include media($md) {
    .text {
      margin-top: 30px;
      margin-bottom: 50px;
    }

    .image {
      // @include remove-break-out();
      position: relative;

      img {
        @include object-fit(cover);
      }
    }
  }

  @include media($lg) {
    .text {
      margin-top: 30px;
      margin-bottom: 70px;
    }

    .image {
      @include remove-break-out();
      height: 100%;
      transform: scale(1.2);
      // max-width: 600px;
      // transform: scale(1.3) translateX(-50px);
      // transform: scale(1.2) translateX(-15%);
    }
  }
}
