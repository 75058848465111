//************************************************************************************
// TYPOGRAPHY
//************************************************************************************


// Headings
// -----------------------------------------------------------------------------------
h1, h2, h3, h4, h4, h5, h6,
.h1, .h2, .h3, .h4, .h4, .h5, .h6 {
  font-family: $alt-font;
  color: $title-color;
  line-height: 1.2;
  margin-top: 0;

  -ms-word-break: break-all;
  -ms-word-wrap: break-all;
  -webkit-word-break: break-word;
  -webkit-word-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

h1, .h1 {
  @include font-size(30);
  @extend .bold;

  @include media($md) {
    @include font-size(48);
  }
}


h2, .h2 {
  @include font-size(26);
  @extend .medium;
  margin-bottom: pxtorem(10);

  @include media($md) {
    @include font-size(42);
  }
}


h3, .h3 {
  @include font-size(22);
  @extend .bold;
  margin-bottom: pxtorem(10);
  color: color(brand-primary);

  @include media($md) {
    @include font-size(36);
  }
}

h4, .h4 {
  @include font-size(20);
  font-weight: 400;
  margin-bottom: pxtorem(30);

  @include media($md) {
    @include font-size(30);
  }
}


// Page & Section Titles
// -----------------------------------------------------------------------------------

.small-title {
  @include font-size(22);
  @extend .bold;
  color: color(brand-primary);
  font-family: $alt-font;
}
// .small-subtitle {
  // font-family: $alt-font;
  // line-height: 22px;
  // font-size: 14px;
  // margin-bottom: pxtoem(28px);
// }
.page-title {
}
.page-subtitle {
}
.section-title {
}
.section-title-big {
}
.section-subtitle {
}


// Links
// -----------------------------------------------------------------------------------
.cta {
  font-weight: 800;
  font-family: $alt-font;
  padding: 16px 24px;
  // padding: pxtorem(16) pxtorem(24);
  display: inline-block;
  transition: color $transition,
              background-color $transition;
  font-size: .7rem;
  border-radius: 3px;
  text-decoration: none !important;

  @include media($md) {
    font-size: inherit;
  }
}

.cta--light {
  background: color(brand-tertiary);
  color: color(brand-primary);
}

.cta--dark {
  @include font-smoothing();
  background: color(brand-secondary);
  color: #fff;
}

.cta + .underline,
.cta + br + .underline {
  margin-top: 8px;
  // display: block;
}


.graphic-cta {
  @extend .bold;
  position: relative;
  margin-left: 28px;
  padding-left: 10px;
  line-height: 28px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    // top: -4px;
    // left: -40px;
    right: 100%;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    order: -1;
  }

  &:after {
    content: '';
    background: url('../grafix/fa-angle-right.svg') no-repeat center center;
    display: block;
    position: absolute;
    top: 0;
    // top: -4px;
    // left: -40px;
    right: 100%;
    width: 28px;
    height: 28px;
    order: -1;
  }
}

.graphic-cta.go-to-top {
  transform: rotate(-90deg);
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 99999;

  @include media($md) {
    right: 40px;
  }

  @include media($md) {
    bottom: 70px;
  }
}

.graphic-cta--light {
  &:before {
    background-color: color(brand-tertiary);
  }
}

.graphic-cta--dark {
  &:before {
    background-color: color(brand-secondary);
  }
}

// Use for tooltips, small buttons, helper, etc.
.small-link {
  text-decoration: underline;
}


// Utilities
// -----------------------------------------------------------------------------------
.underline {
  // background-image: linear-gradient(to right, color(brand-tertiary) 100%);
  // background-position: 0 1.04em;
  // background-repeat: repeat-x;
  // background-size: 8px 3px;
  // display: inline;

  border-bottom: 1px solid color(brand-tertiary);
  padding-bottom: 2px;
  display: inline-block;
  text-decoration: none !important;
}

.underline--light {
  border-color: color(brand-tertiary);
}

.underline--dark {
  border-color: color(brand-secondary);
}

.text-list {
  padding-left: 20px;

  li:before {
    content: '- ';
  }
}

.menu-text {
  @include font-size(20);
  @extend .bold;
  color: $title-color;
  font-family: $alt-font;
}


.lower {
  text-transform: lowercase;
}

.alt-text {
}


// Text usually used for sub section titles
.leaders {
}

// Text aligning
.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

// Used to overwrite font-weight in specific cases
.thin        { font-weight: 100; }
.extra-light { font-weight: 200; } // Ultra Light
.light       { font-weight: 300; } // Book
.normal      { font-weight: 400; }
.medium      { font-weight: 600; }
// .semi-bold   { font-weight: 600; } // Demi bold
.bold        { font-weight: 800; }
// .extra-bold  { font-weight: 800; } // Heavy
.black       { font-weight: 900; }

.looser   { letter-spacing: 2px; }
.loose    { letter-spacing: 1px; }
.tight    { letter-spacing: -2px; }
.tighter  { letter-spacing: -3px; }

.straight { font-style: normal; }
.italic   { font-style: italic; }


// Uppercase and Lowercase text
.upper { text-transform: uppercase; }
.lower { text-transform: lowercase; }
