//************************************************************************************
// GLOBAL VARIABLES
// Regroup every variable definition in here.
//************************************************************************************


// Site & Sidebar width
// -----------------------------------------------------------------------------------
$site-width:    1340px;
$sitebar-width: 450px;
$base-padding: 2%;

$xl: ">=#{pxtoem(1200)}";
$lg: ">=#{pxtoem(992)}";
$md: ">=#{pxtoem(768)}";
$sm: ">=#{pxtoem(576)}";
$xs: "<#{pxtoem(576)}";


// Brand colors for the project
// -----------------------------------------------------------------------------------
$colors: (
  'brand-primary': #000000,
  'brand-secondary': #004F5F,
  'brand-tertiary': #ffcb2d,
  'light-green': #A2CDD6,
  'light-grey': #F7F7F7
);

// $brand-primary: #000000;
// $brand-secondary: #004F5F;
// $brand-tertiary: #FFAE12;
// $light-green: #A2CDD6;
// $light-grey: #F7F7F7;

// $brand-colors: color(brand-primary), $brand-secondary, color(brand-tertiary), $light-green, $light-grey;

// Font Families
// -----------------------------------------------------------------------------------
$default-font:  'ibm-plex-serif', Tahoma, serif; // normal 400 - bold 800
$alt-font:      'ibm-plex-sans', Helvetica, Arial, sans-serif;
$font-size: 14;


// Text colors
// -----------------------------------------------------------------------------------
$title-color:   color(brand-secondary);
$text-color:    color(brand-primary);
$text-dim:      #333333;
$text-faint:    #6a6a6a;
$text-weak:     #999999;


// Paragraph and lists spacing
// -----------------------------------------------------------------------------------
$paragraph-spacing: 1.5em;
$list-spacing:      1.1em;

$transition: 0.3s ease-out;
