//************************************************************************************
// FOOTER
//************************************************************************************

.site-footer {
  @include font-smoothing();
  font-family: $alt-font;
  background-color: color(brand-primary);
  color: #FFF;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background-image: url('../grafix/wave-footer.svg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100% auto;

  a {
    &:hover {
      text-decoration: underline;
    }
    &.underline {
        font-family: $default-font;
      }
  }

  ul {
    li {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .footer-nav {
    @extend .bold;
    margin-bottom: 20px;
  }

  .general-pages {
    margin-top: 20px;
  }


  @include media($md) {
    ul {
      display: flex;
      justify-content: center;

      li {
        + li {
          margin-left: 30px;
        }
      }
    }

    .footer-nav {
      margin-bottom: 90px;
    }

    .general-pages {
      margin-top: 90px;

      ul {
        li + li {
          margin-left: 14px;

          &:before {
            content: '';
            width: 1px;
            height: 10px;
            display: inline-block;
            background: color(brand-tertiary);
            margin-right: 10px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  @include media($lg) {
    ul {
      li {
        + li {
          margin-left: 60px;
        }
      }
    }
  }
}
