$background: #e74c3c;
$select-color: #000;
$select-background: #FFF;
$select-border: 1px solid #000;
$select-width: 220px;
$select-height: 40px;
$select-hover-background: color(brand-secondary);

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}
.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  color: $select-color;
  width: $select-width;
  height: $select-height;
}
.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $select-background;
  border: $select-border;
  border-radius: 4px;
  padding: 8px 15px;
  transition: all .2s ease-in;
  &:after {
    content:"";
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: $select-color transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 10px;
  }
  &:hover {
    background-color: darken($select-background, 2);
  }
  &:active, &.active {
    background-color: darken($select-background, 5);
    &:after {
      top: 9px;
      border-color: transparent transparent $select-color transparent;
    }
  }

  &.selected {
    border-color: color(light-green);
    &:after {
      border-color: color(light-green) transparent transparent transparent;
    }
    &:active, &.active {
      &:after {
        border-color: transparent transparent color(light-green) transparent;
      }
    }
  }
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: darken($select-background, 1);
  li {
    margin: 0;
    padding: 12px 0;
    text-indent: 15px;
    border-top: 1px solid darken($select-background, 10);
    transition: all .15s ease-in;
    &:hover {
      color: $select-background;
      background: $select-hover-background;
    }
    &[rel="hide"] {
      display: none;
    }
  }
}



.realisation-select {
  border-bottom: 1px solid color(light-green);
  padding-bottom: 15px;
  margin-bottom: 30px;
  > span {
    margin-right: 20px;
  }
  @include media($md) {
    margin-bottom: 80px;
  }
}
