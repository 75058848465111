.latest-update {
  @include font-smoothing();
  background-color: color(brand-secondary);
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;

  .text {
    margin-bottom: 30px;

    h2 {
      color: #fff;
      margin-bottom: 45px;
    }

    .small-title {
      color: #FFF;
    }

    .graphic-cta {
      margin-top: 30px;
      display: inline-block;
    }
  }

  @include media($md) {
    padding-top: 50px;
    padding-bottom: 70px;

    .text {
      h2 {
        margin-bottom: 70px;
      }

      .graphic-cta {
        margin-top: 70px;
      }
    }
  }

  @include media($xl) {
    padding-top: 100px;
    padding-bottom: 130px;
  }

  @include media(">#{pxtoem(strip-unit($site-width))}") {
    margin-top: -30px;
  }
}
