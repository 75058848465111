$background: transparent;
$color: #000;
$border: none;
$border-radius: 4px;
$outline: none;
$box-shadow: 0 0 0 1px color(brand-secondary);
$paddingHor: 14px;
$paddingVer: 0;
$fe-font-size: $font-size;
$fe-text-color: $text-color;
$font-weight: 500;

%input-valid {
  box-shadow: 0 0 0 2px #01bf01;
  outline: none;
  border: none;
}

%input-invalid {
  box-shadow: 0 0 0 2px #721c24;
  outline: none;
  border: none;
}

%common-styles {
  @include font-size($fe-font-size);
  font-family: $default-font;
  font-weight: $font-weight;
  width: 100%;
  background: $background;
  border: none;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  outline: $outline;
  padding: $paddingVer $paddingHor;
  height: 2.6rem;
  color: $text-color;
  appearance: none;
  margin-bottom: 6px;

  &:focus {
    outline: none;
    // outline: 1px solid color(brand-secondary);
    box-shadow: 0 0 0 2px color(brand-tertiary);
  }

  .jquery & {
    &.invalid {
      @extend %input-invalid;
    }
  }

  .no-jquery & {
    &:valid:focus {
      @extend %input-valid;
    }

    &:invalid:focus {
      @extend %input-invalid;
    }
  }
}

label,
.label {
  @include font-size($font-size);
  @extend .bold;
  display: block;
  color: color(brand-secondary);
  margin-bottom: 4px;

  // &.required:after {
  //   content: '*';
  // }
}


// Inputfields
// -----------------------------------------------------------------------------
input {
  @extend %common-styles;
}


// Textarea
// -----------------------------------------------------------------------------
textarea {
  @extend %common-styles;
  height: 215px;
  resize: vertical;
  padding: 10px $paddingHor;
  display: block;
}


// Checkbox
// -----------------------------------------------------------------------------
input[type='checkbox'] {
  display: none;
}

input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 52px;
  cursor: pointer;
  user-select: none;
}

// Checkbox itself
$cbsize: 20;
$xSmaller: 6;
$offsetTop: 6;

input[type="checkbox"] + label:before {
  content: '';
  display: block;
  width: $cbsize + px;
  height: $cbsize + px;
  border: $border;
  outline: $outline;
  box-shadow: $box-shadow;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: $offsetTop + px;
}

// Checkbox mark
input[type="checkbox"] + label:after {
  content: '';
  display: block;
  width: ($cbsize - $xSmaller) + px;
  height: ($cbsize - $xSmaller) + px;
  background-color: color(brand-tertiary);
  position: absolute;
  left: $xSmaller / 2 + px;
  top: $xSmaller / 2 + $offsetTop + px;
  opacity: 0;
  transform: scale(0);
  transition: all .2s;
}

input[type="checkbox"]:checked + label:after {
  opacity: .9;
  transform: scale(1);
}

input[type="checkbox"] + label > a {
  color: color(brand-tertiary);
  display: block;
}


// Select
// -----------------------------------------------------------------------------
select {
  @extend %common-styles;
  text-indent: ($paddingHor - 8px);
}


// Buttons
// -----------------------------------------------------------------------------

input[type="submit"] {
  cursor: pointer;
}


// Wrappers
// -----------------------------------------------------------------------------
.input-wrap {
  margin-bottom: 23px;
}


// Form error / success messages
// -----------------------------------------------------------------------------
.form-messages {
  margin-bottom: 20px;

  h4 {
    color: inherit;
  }

  button {
    display: none;
  }
}
