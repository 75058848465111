.contact {
  // padding-top: 0;

  .contact-form-wrap {
    background-color: #FFF;
  }

  @include media($md) {
    // padding: 30px;

    .contact-info {
      address {
        background-color: color('light-grey');
      }
    }

    .contact-form-wrap {
      margin-left: -1.5rem;
      margin-top: 50px;
      max-width: 540px;
    }
  }

  @include media($xl) {
    .contact-form-wrap {
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
      margin-left: -3rem;
    }
  }
}
