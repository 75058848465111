//************************************************************************************
// HEADER
//************************************************************************************

.page-header {
  @include font-smoothing(true);
  color: #FFF;
  // box-shadow: inset 0px 4px 50px rgba(0, 0, 0, 0.25);

  h1 {
    color: #FFF;
    margin-bottom: 50px;
  }

  p {
    color: color(light-green);
    margin-bottom: 50px;
  }

  .text {
    padding-left: 20px;
  }

  @include media($md) {
    .wrapper {
      max-width: 800px;
      width: 80%;
    }
  }
}


.page-header--small {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  h1 {
    margin: 0;
  }

  @include media($md) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}


.page-header--big {
  padding-top: 20px;
  padding-bottom: 20px;

  // @include media($sm) {
  //   // padding: 40px 20px;
  //   padding-top: 40px;
  //   padding-bottom: 40px;
  // }

  @include media($md) {
      padding: 135px 0;

      .wrapper {
        margin-left: 10%;
      }
  }

  @include media($lg) {
    .logo-buysschaert {
      display: block;
      background: url('../grafix/logo-light.svg') no-repeat right 10% center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: 1500px;
      opacity: .1;
    }
  }
}
