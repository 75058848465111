.dynamic-page {
  overflow: hidden;
  color: #000;

  .inner {
    width: 88%;
    max-width: 991px;
    margin: 0 auto;
  }

  h1, h2, h3, h4, h5, h6 {
    // color: #000;
  }

  a {
    text-decoration: underline;

    // &:hover {
    //   color: $blue;
    // }
  }
}
