//************************************************************************************
// STRUCTURE
// General elements of the structure, including containers and wrappers
//************************************************************************************


%wrapper, .wrapper {
  // @extend %h-padding;
  max-width: $site-width;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.site-main {
}

.v-padding-sm {
  padding-top: 30px;
  padding-bottom: 45px;

  @include media($md) {
    padding-top: 50px;
    padding-bottom: 65px;
  }

  @include media($lg) {
    padding-top: 70px;
    padding-bottom: 85px;
  }
}

.v-padding-md {
  padding-top: 40px;
  padding-bottom: 20px;

  @include media($md) {
    padding-top: 70px;
    padding-bottom: 50px;
  }

  @include media($lg) {
    padding-top: 130px;
    padding-bottom: 110px;
  }
}
