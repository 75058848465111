#diensten-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: color(light-green);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  transition: transform $transition;
  transform: translateY(-100%);
  opacity: 0;
  z-index: -50;
  pointer-events: none;

  &.open {
    opacity: 1;
    z-index: 99999;
    pointer-events: auto;
    transform: translateY(0);
    transition: transform $transition,
                opacity $transition;
  }

  .hamburger {
    position: absolute;
    top: 30px;
    right: 10px;
    z-index: 9999;

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      height: 6px;
    }
  }
}

.diensten {
  padding-top: 30px;
  padding-bottom: 30px;

  h1 {
    height: 10vh;
  }

  .title {
    @include font-size(22);
    font-family: $alt-font;
    font-weight: 800;
    color: color(brand-secondary);
    margin-bottom: 20px;
    display: block;
  }

  // .inner {
  //   width: 90%;
  //   margin: 0 auto;
  // }

  .diensten-list {
    text-align: center;

    a:hover {
      text-decoration: underline;
    }

    > li {
      margin-bottom: 30px;

      li {
        margin-bottom: 14px;
      }
    }
  }

  @include media($md) {
    padding-top: 60px;
    padding-bottom: 60px;

    .hamburger {
      position: absolute;
      top: 40px;
      right: 50px;
    }

    .title {
      margin-bottom: 40px;
    }

    .diensten-list {
      text-align: center;

      > li {
        &:first-child {
          margin-bottom: 60px;
        }

        li {
          margin-bottom: 14px;
        }
      }
    }

    &.modal {
      .diensten-list {
        > li {
          height: 40vh;
        }
      }
    }
  }

  @include media($xl) {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
