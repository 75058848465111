//************************************************************************************
// HEADER
//************************************************************************************

.bla {
  grid-column: 1fr 1fr 1frasdfas;
}

.site-header {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;

  .logo {
    // padding-top: 20px;
    // padding-left: 10px;
    display: inline-block;
    margin-bottom: 30px;

    img {
      width: 200px;
    }
  }

  .hamburger {
    margin-left: auto;
    z-index: 9999;
    // margin-right: 20px;
    // margin-top: 30px;
  }

  .diensten-menu {
    display: none;
  }

  @include media($md) {
    .logo {
      margin-bottom: 0;
    }

    .hamburger {
      display: none;
    }

    .text-right {
      text-align: right;
      margin-bottom: 20px;
    }
  }
}
