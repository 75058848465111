//************************************************************************************
// NAVIGATION
// All kind of navigation and menus, including breadcrumb.
//************************************************************************************

.main-nav {
  background: color(brand-secondary);
  font-family: $alt-font;
  color: #FFF;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  transform: translateX(-100%);
  transition: transform $transition;

  &.open {
    transform: translateX(0);
  }

  li {
    padding-top: 10px;
    padding-bottom: 10px;

    &.active a {
      text-decoration: underline;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  @include media($md) {
    position: relative;
    width: auto;
    height: auto;
    display: block;
    background: transparent;
    color: color(brand-primary);
    transform: none;
    font-weight: 800;

    ul {
      display: flex;
      width: 90%;
      max-width: 515px;
      margin-left: auto;
      justify-content: space-between;
    }
  }
}


.site-header,
.site-footer {
  a[href*='diensten'] {
    &:after {
      content: '';
      display: inline-block;
      background: url('../grafix/menu-burger.svg') no-repeat left center;
      width: 10px;
      height: 10px;
      margin-left: 3px;
    }
  }
}
