.alert {
  padding: 10px 18px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;

  p {
    margin-bottom: 0;
  }
}

.alert-danger {
  // @extend .alert;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger-text {
  color: #721c24;
}

.alert-success {
  // @extend .alert;
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
