.category-service {
  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    margin-top: 0;
  }

  .image {
    display: none;
  }

  .small-title {
    margin-left: 0;
  }

  .text {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @include media($md) {
    margin-bottom: 30px;

    h2 {
      margin-left: -30px;
    }

    .bg-light-grey {
      background-color: color(light-grey);
    }

    .text {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .mobile-image {
      display: none;
    }

    .image-wrap {
      margin-left: -60px;
    }

    .image {
      display: block;

      img {
        width: 100%;
      }
    }
  }

  @include media($lg) {
    margin-bottom: 120px;

    .image-wrap {
      margin-left: -95px;
    }
  }

  @include media($xl) {
    margin-bottom: 180px;
  }
}



.category-service.switch {
  &:nth-child(even) {
    @include media($md) {
      > .row > [class*="col-"]:first-child {
        order: 1;
      }

      .text {
        padding-left: 20%;
        padding-right: 10%;
      }

      .image {
        transform: translateX(60px);
      }
    }

    @include media($xl) {
      .text {
        padding-left: 30%;
      }

      .image {
        transform: translateX(95px);
      }
    }
  }

  &:nth-child(odd) {
    @include media($md) {
      .text {
        padding-right: 20%;
        padding-left: 10%;
        margin-bottom: 0;
      }
    }

    @include media($xl) {
      .text {
        padding-left: 30%;
      }
    }
  }
}


.category-service.blog {
  &:not(:last-child) .text {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 5px;
      height: 35px;
      left: 49%;
      left: calc(50% - 2.5px);
      top: 100%;
      background: url('../grafix/dots.svg') center bottom no-repeat;
    }
  }

  @include media($md) {
    margin-bottom: 55px;

    .text {
      padding-left: 50px;
      padding-right: 100px;
    }

    &:not(:last-child) .text {
      &:after {
        top: calc(100% + 10px);
      }
    }
  }

  @include media($lg) {
    .text {
      padding-right: 150px;
    }
  }
}
