.cta-banner {
  @include font-smoothing();
  color: #FFF;
  padding-top: 40px;
  padding-bottom: 40px;

  h2 {
    color: #FFF;
    margin-bottom: 40px;
  }

  .wrapper {
    max-width: 500px;
  }

  @include media($md) {
    padding-top: 80px;
    padding-bottom: 80px;

    h2 {
      margin-bottom: 80px;
    }
  }

  @include media($lg) {
    padding-top: 100px;
    padding-bottom: 110px;
  }
}
